import React from 'react';
import styled from 'styled-components';
import { SEO } from 'components/seo';
import { TAGS } from 'constants/tags';
import Layout from 'layouts/standard';
import { object } from 'prop-types';

const Container = styled.div`
    height: 100vh;
    width: 100%;
    padding-top: 6rem;
`;

const Frame = styled.iframe`
    width: 100%;
    height: 100%;
`;

const RNSelectProDocs = (props) => {
    const { location } = props;
    return (
        <Layout location={location}>
            <Container>
                <Frame
                    src="https://mobilereality.github.io/react-native-select-pro/docs/getting-started/"
                    frameBorder="0"
                />
            </Container>
        </Layout>
    );
};

RNSelectProDocs.propTypes = {
    location: object.isRequired,
};

export default RNSelectProDocs;

export const Head = () => <SEO tags={TAGS.RN_SELECT_PRO_DOCS} />;
